<template>
    <div>
        <div class="h-handle-bg">
            <div class="h-handle-button">
                <p class="h-return">
                    <el-button type="text" size="small" @click="config.isDetailDisplay = false">返回</el-button>
                </p>
                <el-button type="primary" size="small" @click="addRule" v-right-code="'RuleToOrderWT:Edit'">新增</el-button>
                <el-button type="primary" size="small" v-right-code="'RuleToOrderWT:Edit'" @click="saveRule">保存</el-button>
            </div>
        </div>
        <el-tabs type="border-card" v-model="activeName" @tab-click="tab1Click">
            <el-tab-pane name="tabbasic">
                <span slot="label">基本信息</span>
                <div class="form-list">
                    <el-form :model="dataSource" ref="_ruleForm" :rules="projectCheckRule">
                        <el-form-item>
                            <el-col :span="4" class="form-title">
                                <span style="color: red">*</span>规则名称：</el-col>
                            <el-col :span="8">
                                <el-form-item prop="RuleName">
                                    <el-input type="text" v-model="dataSource.RuleName" maxlength="50"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="4" class="form-title">
                                <span style="color: red">*</span>货主名称：</el-col>
                            <el-col :span="8">
                                   <ChooseCustomer v-model="dataSource.CustomerCode" :customerName.sync="dataSource.CustomerName" :onChange="customerChange"></ChooseCustomer>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <el-col :span="4" class="form-title"><span style="color: red">*</span><span>状态：</span></el-col>
                            <el-col :span="8">
                                <el-form-item>
                                    <el-radio v-model="dataSource.EnableStatus" label="100">启用</el-radio>
                                    <el-radio v-model="dataSource.EnableStatus" label="200">停用</el-radio>
                                </el-form-item>
                            </el-col>
                            <el-col :span="4" class="form-title">
                                <span style="color: red">*</span>物流订单类型：</el-col>
                            <el-col :span="8">
                                <el-form-item prop="BusinessType">
                                    <el-select v-model="dataSource.BusinessType" filterable placeholder="请选择">
                                        <el-option v-for="item in logisticsOrderTypeCodeList" :key="item.Value"
                                            :label="item.Label" :value="item.Value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <el-col :span="4" class="form-title"><span style="color: red">*</span>生效日期：</el-col>
                            <el-col :span="8">
                                <el-form-item prop="EffectiveDate">
                                    <el-date-picker v-model.trim="dataSource.EffectiveDate" placeholder="选择日期" value-format="yyyy-MM-dd HH:mm:ss"
                                        type="date"></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="4" class="form-title"><span style="color: red">*</span>失效日期：</el-col>
                            <el-col :span="8">
                                <el-form-item prop="ExpiredDate">
                                    <el-date-picker v-model.trim="dataSource.ExpiredDate" placeholder="选择日期" value-format="yyyy-MM-dd HH:mm:ss"
                                        type="date"></el-date-picker>
                                </el-form-item>
                            </el-col>

                        </el-form-item>
                        <el-form-item>
                            <el-col :span="4" class="form-title">规则描述：</el-col>
                            <el-col :span="20">
                                <el-form-item prop="Remark">
                                    <el-input type="textarea" maxlength="200" :rows="2" placeholder="请输入内容" v-model="dataSource.Remark">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                         <el-form-item>
                            <el-col :span="4" class="form-title"><span style="color: red">*</span>优先级：</el-col>
                            <el-col :span="8">
                                <el-form-item prop="Priority">
                                     <el-input type="text" maxlength="5" v-model.number="dataSource.Priority"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <p>执行结果</p>
                        </el-form-item>
                        <el-form-item>
                            <el-col :span="4" class="form-title">
                                <span style="color: red">*</span>执行类型：</el-col>
                            <el-col :span="8">
                                <el-form-item prop="OrderWTType">
                                    <el-select v-model="dataSource.OrderWTType" filterable placeholder="请选择"
                                        @change="initGetPrompt">
                                        <el-option v-for="item in WarehouseLinkTypeList" :key="item.Value" :label="item.Label"
                                            :value="item.Value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <el-col v-if="dataSource.OrderWTType != null" :span="2" class="form-title"
                                style="color:grey">提示：</el-col>
                            <p v-if="dataSource.OrderWTType != null" style="color:grey">{{ this.Prompt }}</p>
                        </el-form-item>
                    </el-form>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
export default {
    data() {
        var _this = this;
        var checkRuleName = function (rule, value, callback) {
            if (!value) return callback(new Error("请输入规则名称"));
            if(_this.Utils.isEmpty(value)) return callback(new Error("请输入规则名称"));
            callback();
        };
        var checkBusinessType = function (rule, value, callback) {
            if (value == null || value.length == 0) return callback(new Error('请选择物流订单类型'));
            callback();
        };
        var checkOrderWTType = function (rule, value, callback) {
            if (value == null || value.length == 0) return callback(new Error('请选执行类型'));
            callback();
        };

        var checkPriority= function (rule, value, callback) {
        if (value == null || value.length == 0)
            return callback(new Error("优先级不能为空"));
        if(value>32767)
            return callback(new Error("优先级数值过大"));
        callback();
        };

        return {
            logisticsOrderTypeCodeList: [],
            WarehouseLinkTypeList: [],            
            // PromptList: [{
            //     value: '1',
            //     label: '适用于按照仓库出库数据进行运力配载。当选择以仓驱配时，优先进行仓库单据调度，根据调度执行结果驱动运力调度。'
            // }, {
            //     value: '2',
            //     label: '适用于按照运力能力进行仓库出库作业。当选择以配驱仓时，优先运力配载计算，根据运力配载计算结果执行仓库作业。'
            // }, {
            //     value: '3',
            //     label: '适用于正常的仓配调度场景。当选择同仓同配时，仓库单据和运输单据同时开始作业调度，互不干扰。'
            // }],
            projectList: [],
            projectCheckRule: {
                RuleName: [{ validator: checkRuleName, trigger: "blur" }],
                BusinessType: [{ validator: checkBusinessType, trigger: 'blur' }],
                OrderWTType: [{ validator: checkOrderWTType, trigger: 'blur' }],
                Priority:[{ validator: checkPriority, trigger: "blur" }],
            },
            activeName: "tabbasic",
            Prompt: "",
        }
    },
    props: {
        config: {
            isDetailDisplay: false,
        },
        dataSource: {},
    },
    computed: {},
    watch: {
        dataSource: {
            handler(curVal, oldVal) {
                this.isDisabled =
                    this.Utils.emptyGuid != this.dataSource.WarehouseProjectID;
                this.initialize();
            },
            deep: true,
        },
    },
    mounted() {
        var _this = this;
        this.Event.$on("clearEditruletoorderdisassemblyForm", () => this.resetForm);
        this.initProjectList();
        this.initialize();
        this.initLogisticsOrderTypeCodeList();
        this.initWarehouseLinkTypeList();
    },
    methods: {
        initWarehouseLinkTypeList: function () {
            var _this = this;
            _this.$ajax.send(
                "omsapi/ruleToOrderConnect/getWarehouseLinkTypeList",
                "get",
                {},
                (data) => {
                    if (data.IsSuccess) {
                        _this.WarehouseLinkTypeList = data.Result;
                    }
                }
            );
        },
        initLogisticsOrderTypeCodeList: function () {
            var _this = this;
            _this.$ajax.send(
                "omsapi/ruleToOrderWT/getLogisticsOrderTypeCodeList",
                "get",
                {},
                (data) => {
                    if (data.IsSuccess) {
                        _this.logisticsOrderTypeCodeList = data.Result;
                    }
                }
            );
        },
        initialize() {
            this.initGetPrompt(this.dataSource.OrderWTType);
        },
        initGetPrompt: function (val) {
            var _this = this;
            if (val == 1) {
                _this.Prompt = "适用于按照仓库出库数据进行运力配载。当选择以仓驱配时，优先进行仓库单据调度，根据调度执行结果驱动运力调度。";
            }
            if (val == 2) {
                _this.Prompt = "适用于按照运力能力进行仓库出库作业。当选择以配驱仓时，优先运力配载计算，根据运力配载计算结果执行仓库作业。";
            }
            if (val == 3) {
                _this.Prompt = "适用于正常的仓配调度场景。当选择同仓同配时，仓库单据和运输单据同时开始作业调度，互不干扰。";
            }
        },
        tab1Click: function (tab, event) { },
        addRule: function () {
            this.saveRule();
        },
        saveRule: function () {
            var _this = this;
            _this.$refs["_ruleForm"].validate((valid) => {
                _this.isPassValidate = valid;
                if (valid) {
                     if(_this.dataSource.CustomerCode==null || _this.dataSource.CustomerCode.length==0)
                        {
                            _this.Utils.messageBox("未选择货主名称", "error");
                            return ;
                        }
                    _this.$ajax.send(
                        "omsapi/ruleToOrderWT/createOrEdit",
                        "post",
                        _this.dataSource,
                        (data) => {

                            if (data.IsSuccess) {
                                _this.Utils.messageBox(data.OperationDesc, "success");
                                _this.$parent.syncDataSource();
                                _this.Event.$emit("reloadRuleList");
                                // _this.dataSource.Detail = [];
                                // _this.$refs["_ruleForm"].resetFields(); //清空表单
                                _this.inputValue = "";
                                _this.Utils.messageBox("保存成功.", "success");
                                _this.config.isDetailDisplay = false;
                            } else {
                                _this.Utils.messageBox(data.OperationDesc, "error");
                            }
                        }
                    );
                } else {
                    return false;
                }
            });
        },
        initProjectList: function () {
            var _this = this;
                return;
            _this.$ajax.send(
                "omsapi/ruletoorderdisassembly/getprojectlist", "get", {}, (data) => {
                    if (data.IsSuccess) {
                        _this.projectList = data.Result;
                    }
                }
            );
        },
         customerChange(){
                //this.warehouseConfig.CustomerCode=this.dataSource.CustomerCode
                //this.productConfig.CustomerCode=this.dataSource.CustomerCode
            },
    },
}
</script>